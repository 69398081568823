import React from 'react'
import { Button, makeStyles, SizeProvider } from '@seahorse/ui'
import { broadcast, open, raiseIntent } from '@seahorse/api/fdc3'
import { notify } from '@seahorse/api'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  button: {
    margin: 5,
    width: 'calc(50% - 10px)',
  },
})

const Widget1: React.FunctionComponent = () => {
  const { classes } = useStyles()
  return (
    <SizeProvider value="small">
      <div className={classes.container}>
        <Button onClick={() => open({ appId: 'seahorse__storybook' })} className={classes.button}>
          Open Docs
        </Button>
        <Button
          onClick={() => broadcast({ type: 'seahorse.test', id: { now: new Date().toLocaleTimeString() } })}
          className={classes.button}
        >
          Broadcast
        </Button>
        <Button
          onClick={() => raiseIntent('seahorse.ViewColor', { type: 'seahorse.color', hexValue: '#F00' })}
          className={classes.button}
        >
          Raise Intent
        </Button>
        <Button onClick={() => notify({ title: 'Hello World!', content: '' })} className={classes.button}>
          Notify
        </Button>
      </div>
    </SizeProvider>
  )
}

export default Widget1
