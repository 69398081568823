import React, { useState, FC } from 'react'
import { blink } from '@seahorse/api'
import { Button, TextField, SizeProvider, Switch } from '@seahorse/ui'
import { useStyles } from './BlinkWidget.styles'

const BlinkTest: FC = () => {
  const { classes } = useStyles()
  const [selectedTarget, setSelectedTarget] = useState('example')
  const [hold, setHold] = useState(false)

  return (
    <SizeProvider value="small">
      <div className={classes.row}>
        <Switch label="Hold" onChange={setHold} value={hold} labelPosition="top" className={classes.rightSpacing} />

        <TextField
          label="Target"
          labelPosition="top"
          variant="underline"
          value={selectedTarget}
          onChange={setSelectedTarget}
          placeholder="Target"
        ></TextField>
      </div>

      <Button
        className={classes.button}
        onClick={() => {
          blink({
            widgetId: selectedTarget,
            animationType: hold ? 'blinkAndHold' : 'blink',
          })
        }}
      >
        Blink {hold ? ' and Hold' : ''}
      </Button>
    </SizeProvider>
  )
}

export default BlinkTest
