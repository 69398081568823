import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Typography } from '@seahorse/ui'
import { useParams } from 'react-router-dom'
import { logger } from '@seahorse/api'
import { addContextListener, Listener } from '@seahorse/api/fdc3'

export const useStyles = makeStyles({ name: 'ColorViewer' })((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  color: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    backgroundColor: theme.globallink.global.contrastBackground,
    color: theme.globallink.global.accentPrimary,
  },
}))

const ColorViewer: FC = () => {
  const { classes } = useStyles()
  const { color: colorParam = '' } = useParams<{ color?: string }>()
  const [color, setColor] = useState(colorParam)

  useEffect(() => {
    let listener: Listener

    const init = async () => {
      listener = await addContextListener('seahorse.color', (context) => {
        logger.log('Received context', context)
        const hexValue = context?.hexValue

        if (hexValue) {
          setColor(hexValue.slice(1))
        }
      })
    }

    init()

    return () => listener?.unsubscribe()
  }, [])

  useEffect(() => {
    setColor(colorParam)
  }, [colorParam])

  logger.log('Color:' + color)

  return (
    <div className={classes.container}>
      <div className={classes.color} style={{ background: `#${color}` }}>
        <Typography className={classes.text}>#{color}</Typography>
      </div>
    </div>
  )
}

export default ColorViewer
