import React from 'react'
import { makeStyles, useTheme } from '@seahorse/ui'
import { StackBarChart, Legend, BarChartOptions } from '@seahorse/charts'
import { useFluidWidgetFontSizeClassName } from '@seahorse/platform-ui'

const mockGraphData = [
  {
    title: 'Matched',
    Ineligible: 22,
    Pending: 5,
    Cleared: 10,
    Rejected: 18,
  },
  {
    title: 'Unmatched',
    Ineligible: 12,
    Pending: 5,
    Cleared: 0,
    Rejected: 8,
  },
  {
    title: 'Mismatched',
    Ineligible: 0,
    Pending: 0,
    Cleared: 5,
    Rejected: 12,
  },
]

const options: BarChartOptions = {
  yAxis: {
    width: 75,
  },
  xAxis: {
    height: 15,
    hideAxisLine: true,
  },
  bars: {
    size: 5,
  },
  tooltip: {
    show: true,
  },
  margin: {
    right: 30,
  },
  barCounts: {
    show: true,
  },
  hideTicks: true,
}

const legendItems = [
  {
    key: 'Ineligible',
    label: 'Ineligible',
  },
  {
    key: 'Pending',
    label: 'Pending',
  },
  {
    key: 'Cleared',
    label: 'Cleared',
  },
  {
    key: 'Rejected',
    label: 'Rejected',
  },
]

const useStyles = makeStyles()({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    gridRowGap: 4,
    width: '100%',
  },
})

const TradeNexusStackChart: React.FunctionComponent = () => {
  const { classes } = useStyles()
  const theme = useTheme()
  const fluidFontSizeClassName = useFluidWidgetFontSizeClassName({ min: 10, max: 12 })

  const colors = {
    Pending: theme.globallink.colors.DataTurquoise[500],
    Ineligible: theme.globallink.colors.DataOrange[500],
    Cleared: theme.globallink.colors.DataPurple[500],
    Rejected: theme.globallink.colors.DataAmber[600],
  }

  return (
    <div className={classes.wrapper}>
      <Legend items={legendItems} colors={colors} size="small" />
      <StackBarChart
        className={fluidFontSizeClassName}
        data={mockGraphData}
        barFields={['Ineligible', 'Pending', 'Cleared', 'Rejected']}
        variant="horizontal"
        colors={colors}
        options={options}
        labelField="title"
      />
    </div>
  )
}

export default TradeNexusStackChart
