import React, { FC, useState } from 'react'
import { getDefaultDimensions, getDimensions, setDefaultDimensions, setDimensions } from '@seahorse/api'
import { Button, Switch, NumberField, SizeProvider } from '@seahorse/ui'
import { useStyles } from './DimensionWidget.styles'

const DimensionWidget: FC = () => {
  const { classes, cx } = useStyles()
  const [isDefault, setIsDefault] = useState(false)
  const [width, setWidth] = useState<number | null>(500)
  const [height, setHeight] = useState<number | null>(500)

  return (
    <SizeProvider value="small">
      <div className={classes.row}>
        <Switch
          label="Default"
          onChange={setIsDefault}
          value={isDefault}
          labelPosition="top"
          className={classes.rightSpacing}
        />
        <NumberField label="Width" value={width} onChange={setWidth} className={classes.rightSpacing} />
        <NumberField label="Height" value={height} onChange={setHeight} />
      </div>
      <div className={classes.row}>
        <Button
          className={cx(classes.rightSpacing, classes.button)}
          onClick={async () => {
            const result = isDefault ? await getDefaultDimensions() : await getDimensions()
            setWidth(result.width)
            setHeight(result.height)
          }}
        >
          Get {isDefault ? 'default' : ''} dims
        </Button>
        <Button
          className={classes.button}
          disabled={width === null || height === null}
          onClick={() => {
            if (width && height) {
              isDefault ? setDefaultDimensions({ width, height }) : setDimensions({ width, height })
            }
          }}
        >
          Set {isDefault ? 'default' : ''} dims
        </Button>
      </div>
    </SizeProvider>
  )
}

export default DimensionWidget
