import React from 'react'
import { makeStyles } from '@seahorse/ui'
import { BarChart, BarChartOptions } from '@seahorse/charts'

const mockGraphData = {
  'Segment 1': 2,
  'Segment 2': 3,
  'Segment 3': 4,
  'Segment 4': 5,
}

const options: BarChartOptions = {
  axes: {
    fontSize: 10,
  },
  yAxis: {
    width: 70,
  },
  xAxis: {
    height: 15,
    hideAxisLine: true,
  },
  bars: {
    size: 6,
  },
  tooltip: {
    fontSize: 10,
    show: true,
  },
  margin: {
    top: 4,
    bottom: 4,
  },
}

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    color: theme.globallink.colors.DataTurquoise[900],
  },
}))

const TradeNexusHorizontalBar: React.FunctionComponent = () => {
  const { classes } = useStyles()
  return (
    <div className={classes.wrapper}>
      <BarChart data={mockGraphData} colors={{}} variant="horizontal" options={options} />
    </div>
  )
}

export default TradeNexusHorizontalBar
