import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '80px auto',
    gridColumnGap: '5px',
    width: '100%',
    color: theme.globallink.colors.DataTurquoise[900],
  },
}))
