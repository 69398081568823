import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'BlinkWidget' })({
  rightSpacing: {
    marginRight: 5,
  },
  row: {
    display: 'flex',
    marginBottom: 5,
  },
  button: {
    width: '100%',
  },
})
