import React from 'react'
import { Button } from '@seahorse/ui'
import { requireSessionRefresh } from '@seahorse/api'
import { useStyles } from './SessionRefreshWidget.styles'

const SessionRefreshDemo = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Button size="small" className={classes.button} onClick={() => requireSessionRefresh()}>
        Refresh
      </Button>
      <Button
        size="small"
        className={classes.button}
        onClick={() =>
          requireSessionRefresh({
            applyToGroup: true,
          })
        }
      >
        Refresh Group
      </Button>
    </div>
  )
}

export default SessionRefreshDemo
