import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Typography, useTheme } from '@seahorse/ui'
import { useParams } from 'react-router-dom'
import { logger } from '@seahorse/api'
import { addContextListener, Listener } from '@seahorse/api/fdc3'

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  text: {
    backgroundColor: theme.globallink.global.contrastBackground,
    color: theme.globallink.global.accentPrimary,
  },
}))

const GradientViewer: FC = () => {
  const { classes } = useStyles()
  const theme = useTheme()
  const { colors: colorsParam = '' } = useParams<{ colors?: string }>()
  const [colors, setColors] = useState(colorsParam.split(','))
  const gradient = colors.map((color) => `#${color}`).join(', ')

  useEffect(() => {
    let listener: Listener
    const init = async () => {
      listener = await addContextListener('seahorse.colorList', (context) => {
        logger.log('Received context', context)
        const hexValues = context?.colors?.map((x: any) => x.hexValue.slice(1))

        setColors(hexValues)
      })
    }

    init()

    return () => listener?.unsubscribe()
  }, [])

  useEffect(() => {
    const parsedColors = colorsParam.split(',')
    if (parsedColors.length < 2) {
      // ensure we have at least 2 colors
      parsedColors.push(theme.globallink.global.primary.slice(1))
    }
    setColors(parsedColors)
  }, [colorsParam, theme.globallink.global.primary])

  logger.log('Gradient', gradient)

  return (
    <div className={classes.container} style={{ background: `linear-gradient(90deg, ${gradient})` }}>
      <Typography className={classes.text}>{gradient}</Typography>
    </div>
  )
}

export default GradientViewer
