import React, { useState, FC } from 'react'
import { Button, RadioGroup, RadioGroupItemProps, SizeProvider } from '@seahorse/ui'
import { notify, NotificationLevel, NotifyAction } from '@seahorse/api'
import { useStyles } from './NotificationWidget.styles'

const notificationLevels: RadioGroupItemProps[] = [
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Med.',
    value: 'medium',
  },
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'None',
    value: 'none',
  },
]

const mockActions: NotifyAction[] = [
  {
    type: 'intent',
    title: 'View Color',
    intent: 'seahorse.ViewColor',
    context: { type: 'seahorse.color', hexValue: '#155799' },
  },
]

const NotificationWidget: FC = () => {
  const [notificationLevel, setNotificationLevel] = useState<NotificationLevel>('none')
  const { classes } = useStyles()

  return (
    <SizeProvider value="small">
      <div className={classes.root}>
        <RadioGroup
          row
          className={classes.radioGroup}
          items={notificationLevels}
          value={notificationLevel}
          onChange={(level: string | null) => {
            if (level) {
              setNotificationLevel(level as NotificationLevel)
            }
          }}
        />

        <Button
          onClick={() => {
            notify({
              title: 'New Color',
              content: [
                'New color available! Click {0} to view it.',
                {
                  text: 'here',
                  intent: mockActions[0].intent,
                  context: mockActions[0].context,
                },
              ],
              actions: mockActions,
              level: notificationLevel,
            })
          }}
        >
          Notify
        </Button>
      </div>
    </SizeProvider>
  )
}

export default NotificationWidget
