import { Typography, makeStyles } from '@seahorse/ui'
import React from 'react'
import { FxConnectDemoWrapper } from './FxConnectDemoWrapper'

export const useStyles = makeStyles()((theme) => ({
  container: {
    margin: theme.spacing(4),
  },
}))

export const FxConnectDemoHistory: React.FC = () => {
  const { classes } = useStyles()
  return (
    <FxConnectDemoWrapper>
      <div className={classes.container}>
        <Typography variant="subtitle2">FXConnect Interop Demo</Typography>
        <Typography variant="h4" gutterBottom>
          Order History
        </Typography>
      </div>
    </FxConnectDemoWrapper>
  )
}
