import React from 'react'
import { createRoot } from 'react-dom/client'
import { SeahorseProvider } from '@seahorse/platform-ui'
import AppRoutes from './AppRoutes'

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <SeahorseProvider>
        <AppRoutes />
      </SeahorseProvider>
    </React.StrictMode>,
  )
} else {
  console.error('No container found')
}

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://snowpack.dev/concepts/hot-module-replacement
if (import.meta.hot) {
  import.meta.hot?.accept()
}
