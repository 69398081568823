import { DashBarWidgetContent } from '@seahorse/platform-ui'
import React from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import App from './App'
import { ColorSelectorWidget, ColorViewer, GradientViewer } from './color'
import FxConnectInterop from './dashbar/FXConnectInterop'
import FxConnectHorizontalBar from './dashbar/FxConnectKPIHorizontalBar'
import FxConnectStackChart from './dashbar/FxConnectKPIStackChart'
import TradeNexusHorizontalBar from './dashbar/TradeNexusHorizontalBar'
import TradeNexusKPIHorizontalBar from './dashbar/TradeNexusKPIHorizontalBar'
import TradeNexusKPIStackChart from './dashbar/TradeNexusKPIStackChart'
import TradeNexusStackChart from './dashbar/TradeNexusStackChart'
import Widget1 from './dashbar/Widget1'
import { NotificationWidget } from './NotificationWidget'
import { BadgeWidget } from './BadgeWidget'
import { FxConnectDemoHistory } from './fxcDemo/FxConnectDemoHistory'
import { FxConnectDemoOrders } from './fxcDemo/FxConnectDemoOrders'
import { SessionRefreshWidget } from './SessionRefreshWidget'
import { PreferencesWidget } from './preferencesWidget'
import { DownloadBlotter } from './DownloadBlotter'
import SymphonyTest from './symphony/SymphonyTest'
import Wrapper from './Wrapper'

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route path="/symphony" element={<SymphonyTest />} />
          <Route path="/download-blotter" element={<DownloadBlotter />} />
          <Route path="/color-viewer" element={<ColorViewer />} />
          <Route path="/color-viewer/:color" element={<ColorViewer />} />
          <Route path="/gradient-viewer/:colors" element={<GradientViewer />} />
          <Route
            path="/dashbar/color-selector"
            element={
              <DashBarWidgetContent>
                <ColorSelectorWidget />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/1"
            element={
              <DashBarWidgetContent>
                <Widget1 />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/session-refresh"
            element={
              <DashBarWidgetContent>
                <SessionRefreshWidget />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/notifications"
            element={
              <DashBarWidgetContent>
                <NotificationWidget />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/preferences"
            element={
              <DashBarWidgetContent>
                <PreferencesWidget />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/badges"
            element={
              <DashBarWidgetContent>
                <BadgeWidget />
              </DashBarWidgetContent>
            }
          />
          <Route path="/dashbar/fx-connect-interop" element={<FxConnectInterop />} />
          <Route path="/fxc-demo/orders" element={<FxConnectDemoOrders />} />
          <Route path="/fxc-demo/history" element={<FxConnectDemoHistory />} />
          <Route
            path="/dashbar/trade-nexus-stack"
            element={
              <DashBarWidgetContent>
                <TradeNexusStackChart />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/trade-nexus-kpi-stack"
            element={
              <DashBarWidgetContent>
                <TradeNexusKPIStackChart />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/fx-connect-stack"
            element={
              <DashBarWidgetContent>
                <FxConnectStackChart />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/trade-nexus-horizontal"
            element={
              <DashBarWidgetContent>
                <TradeNexusHorizontalBar />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/trade-nexus-kpi-horizontal"
            element={
              <DashBarWidgetContent>
                <TradeNexusKPIHorizontalBar />
              </DashBarWidgetContent>
            }
          />
          <Route
            path="/dashbar/fx-connect-horizontal"
            element={
              <DashBarWidgetContent>
                <FxConnectHorizontalBar />
              </DashBarWidgetContent>
            }
          />
          <Route index element={<App />} />
        </Routes>
      </Wrapper>
    </Router>
  )
}

export default AppRoutes
