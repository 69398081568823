import React from 'react'
import { KPICurrency, makeStyles, useTheme } from '@seahorse/ui'
import { BarChartOptions, Legend, StackBarChart } from '@seahorse/charts'

const mockGraphData = [
  {
    currencyPair: 'GBP/USD',
    Buy: 58,
    Sell: 42,
  },
  {
    currencyPair: 'EUR/USD',
    Buy: 40,
    Sell: 60,
  },
  {
    currencyPair: 'USD/JPY',
    Buy: 35,
    Sell: 65,
  },
  {
    currencyPair: 'USD/BRL',
    Buy: 34,
    Sell: 66,
  },
  {
    currencyPair: 'EUR/JPY',
    Buy: 76,
    Sell: 24,
  },
]

const options: BarChartOptions = {
  yAxis: {
    width: 50,
  },
  xAxis: {
    height: 15,
    hideAxisLine: true,
  },
  bars: {
    size: 5,
  },
  tooltip: {
    show: true,
  },
  margin: {},
  hideTicks: true,
}

const legendItems = [
  {
    key: 'Buy',
    label: 'Buy',
  },
  {
    key: 'Sell',
    label: 'Sell',
  },
]

const useStyles = makeStyles()(() => ({
  wrapper: () => ({
    display: 'grid',
    gridTemplateColumns: '90px auto',
    gridColumnGap: '2px',
    width: '100%',
    fontSize: 10,
  }),
  chartWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '10px 1fr',
    gridRowGap: 2,
  },
}))

const FxConnectStackChart: React.FunctionComponent = () => {
  const theme = useTheme()
  const { classes } = useStyles()

  const colors = {
    Buy: theme.globallink.colors.DataTurquoise[500],
    Sell: theme.globallink.colors.DataOlive[600],
  }

  return (
    <div className={classes.wrapper}>
      <KPICurrency value={388000000} label="GROSS USD" />
      <div className={classes.chartWrapper}>
        <Legend items={legendItems} colors={colors} size="small" />
        <StackBarChart
          data={mockGraphData}
          barFields={['Buy', 'Sell']}
          variant="horizontal"
          colors={colors}
          options={options}
          labelField="currencyPair"
        />
      </div>
    </div>
  )
}

export default FxConnectStackChart
