import React, { useEffect, useState, FC } from 'react'
import { getEnvironmentId, getWindowId } from '@seahorse/api'
import { addContextListener, addIntentListener, Listener } from '@seahorse/api/fdc3'
import { Button, Typography } from '@seahorse/ui'
import { useNavigate } from 'react-router-dom'
import { BadgeWidget } from './BadgeWidget'
import { BlinkWidget } from './BlinkWidget'
import { SessionRefreshWidget } from './SessionRefreshWidget'
import { DimensionWidget } from './DimensionWidget'
import { NotificationWidget } from './NotificationWidget'
import { ColorSelectorWidget } from './color'
import { PreferencesWidget } from './preferencesWidget'
import Widget1 from './dashbar/Widget1'
import { useStyles, useWidgetStyles } from './App.styles'

const WidgetContainer: FC<{
  title: string
  className?: string
  children?: React.ReactNode
}> = (props) => {
  const { classes, cx } = useWidgetStyles()
  const { className, title, children } = props
  return (
    <div className={cx(className, classes.root)}>
      <div className={classes.header}>
        <Typography variant="body2">{title}</Typography>
      </div>
      {children}
    </div>
  )
}

const App: FC = () => {
  const navigate = useNavigate()
  const [env, setEnv] = useState<string>()
  const [windowId, setWindowId] = useState('')
  const [context, setContext] = useState<string>('')
  const { classes } = useStyles()
  const [preference, setPreference] = useState<string>()

  useEffect(() => {
    const init = async () => {
      const env = await getEnvironmentId()
      const myWindowId = await getWindowId()
      setEnv(env)
      setWindowId(myWindowId)
    }

    init()
  }, [])

  useEffect(() => {
    let listener: Listener
    const init = async () => {
      listener = await addContextListener(null, (context) => context && setContext(JSON.stringify(context)))
    }
    init()

    return () => listener?.unsubscribe()
  }, [])

  useEffect(() => {
    let listener: Listener

    const init = async () => {
      listener = await addIntentListener('seahorse.GetPreference', (context) => {
        setPreference(JSON.stringify(context))
      })
    }

    init()

    return () => listener?.unsubscribe()
  }, [])

  return (
    <div className={classes.container}>
      <Typography variant="h4">Simple App</Typography>

      <div className={classes.info}>
        <Typography variant="subtitle2" gutterBottom>
          Environment: {env || 'N/A'}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Context: {context || 'N/A'}
        </Typography>
        <Typography variant="subtitle2">WindowId: {windowId || 'N/A'}</Typography>
        <Typography variant="subtitle2">Preference: {preference || 'N/A'}</Typography>
      </div>

      <Typography variant="body1">Toolkit</Typography>
      <div className={classes.widgetGrid}>
        <WidgetContainer title="Badges" className={classes.widget}>
          <BadgeWidget />
        </WidgetContainer>

        <WidgetContainer title="Blink" className={classes.widget}>
          <BlinkWidget />
        </WidgetContainer>

        <WidgetContainer title="Notifications" className={classes.widget}>
          <NotificationWidget />
        </WidgetContainer>

        <WidgetContainer title="Interop" className={classes.widget}>
          <ColorSelectorWidget />
        </WidgetContainer>

        <WidgetContainer title="Session Refresh" className={classes.widget}>
          <SessionRefreshWidget />
        </WidgetContainer>

        <WidgetContainer title="Window Dimensions" className={classes.widget}>
          <DimensionWidget />
        </WidgetContainer>

        <WidgetContainer title="Preferences" className={classes.widget}>
          <PreferencesWidget />
        </WidgetContainer>

        <WidgetContainer title="Misc" className={classes.widget}>
          <Widget1 />
        </WidgetContainer>
      </div>

      <Typography variant="body1">Others</Typography>
      <div className={classes.links}>
        <Button variant="text" onClick={() => navigate('/symphony')}>
          Symphony Demo
        </Button>
        <Button variant="text" onClick={() => navigate('/download-blotter')}>
          Download Blotter
        </Button>
        <Button variant="text" onClick={() => window.open('/download-blotter/#/download-blotter')}>
          Window Open
        </Button>
      </div>
    </div>
  )
}

export default App
