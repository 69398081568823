import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'App' })((theme) => ({
  container: {
    margin: theme.spacing(4),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 50,
  },
  widgetGrid: {
    display: 'flex',
    flexFlow: 'wrap',
    marginBottom: 10,
  },
  widget: {
    margin: 5,
  },
  links: {
    display: 'flex',
  },
}))

export const useWidgetStyles = makeStyles()((theme) => ({
  root: {
    border: `1px solid ${theme.globallink.global.accentPrimary}`,
    width: 307,
    height: 116,
    padding: '0px 5px',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 22,
  },
}))
