import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'BadgeWidget' })({
  root: {
    position: 'relative',
    bottom: 8,
    margin: 'auto',
    width: '100%',
  },
  radioGroup: {
    position: 'relative',
    right: 10,
    bottom: 2,
    '.MuiRadio-root': {
      padding: 6,
    },
  },
  checkboxGroup: {
    height: 25,
    position: 'relative',
    bottom: 15,
    right: 12,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    bottom: 8,
  },
  targetTextField: {
    width: '50%',
  },
  button: {
    marginRight: 10,
  },
})
