import { Context, raiseIntent } from '@seahorse/api/fdc3'
import { Button, makeStyles, Typography } from '@seahorse/ui'
import React from 'react'
import { FxConnectDemoWrapper } from './FxConnectDemoWrapper'

export const useStyles = makeStyles()((theme) => ({
  container: {
    margin: theme.spacing(4),
  },
}))

export const FxConnectDemoOrders: React.FC = () => {
  const { classes } = useStyles()

  const onViewTca = () => {
    const context: Context & Record<string, any> = {
      type: 'seahorse.orders',
      orders: [
        {
          type: 'seahorse.order',
          id: {
            orderId: 'example-order-id-123',
          },
          instrument: {
            type: 'fdc3.instrument',
            id: {
              ticker: 'USDGBP',
            },
          },
          ccy: 'USD',
          direction: 'BUY',
          amount: 1000000.0,
        },
      ],
    }

    raiseIntent('ViewAnalysis', context)
  }

  return (
    <FxConnectDemoWrapper>
      <div className={classes.container}>
        <Typography variant="subtitle2">FXConnect Interop Demo</Typography>
        <Typography variant="h4" gutterBottom>
          Orders
        </Typography>
        <Button onClick={onViewTca}>View pre-trade TCA</Button>
      </div>
    </FxConnectDemoWrapper>
  )
}
