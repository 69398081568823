import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'NotificationWidget' })({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: 'auto',
  },
  radioGroup: {
    position: 'relative',
    right: 8,
    '.MuiRadio-root': {
      padding: 6,
    },
  },
})
