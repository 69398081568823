import { raiseIntent } from '@seahorse/api/fdc3'
import { DashBarWidgetContent } from '@seahorse/platform-ui'
import { Button } from '@seahorse/ui'
import React from 'react'

const FXConnectInterop: React.FunctionComponent = () => {
  return (
    <DashBarWidgetContent>
      <Button
        size="small"
        onClick={() =>
          // When user clicks on "Orders", navigate to orders page in FXConnect window
          raiseIntent(
            'seahorse.ShowOrders', // Raise the SeaHorse ShowOrders intent
            { type: 'seahorse.orders' }, // Empty SeaHorse orders context type as types are needed for routing of intents
            { appId: 'fxconnect__demo-window' }, // Target explicitly the fxconnect window
          )
        }
      >
        Orders (3) 126M
      </Button>
      <Button
        size="small"
        onClick={() =>
          // When user clicks on "History", navigate to order history page in FXConnect window
          raiseIntent(
            'seahorse.ShowOrderHistory', // Raise the SeaHorse ShowOrderHistory intent
            { type: 'seahorse.orders' },
            { appId: 'fxconnect__demo-window' }, // Target explicitly the fxconnect window
          )
        }
      >
        History (12)
      </Button>
    </DashBarWidgetContent>
  )
}

export default FXConnectInterop
