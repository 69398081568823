import { notify } from '@seahorse/api'
import { raiseIntent } from '@seahorse/api/fdc3'
import { Button, Typography } from '@seahorse/ui'
import React from 'react'

const chatMessage = {
  text: {
    'text/markdown': '\n*New Order assigned*\n\n§[Launch FX Connect](id/button1) §[View Analysis in BestX](id/button2)',
  },
  entities: {
    button1: {
      type: 'fdc3.fdc3Intent',
      data: {
        title: 'Launch FX Connect',
        intent: 'seahorse.LaunchApp',
        context: {
          type: 'seahorse.App',
          id: {
            appId: 'fxconnect__trading',
          },
        },
      },
    },
    button2: {
      type: 'fdc3.fdc3Intent',
      data: {
        title: 'View Analysis in BestX',
        intent: 'ViewAnalysis',
        context: {
          type: 'globallink.orderList',
          orders: [
            {
              type: 'globallink.order',
              id: { orderId: '62759633', clientOrderId: null },
              instrument: { type: 'fdc3.instrument', id: { ticker: 'EURUSD' } },
              baseCcy: 'EUR',
              direction: 'BUY',
              orderType: 'OUTRIGHT',
              account: 'TEST_QA',
              isNonDeliverable: false,
              amount: 1000000,
              farAmount: null,
              settlementDate: '20210311',
              farSettlementDate: null,
              maturityDate: null,
              farMaturityDate: null,
            },
          ],
        },
      },
    },
  },
}

const chatInit = {
  type: 'fdc3.chat.initSettings',
  message: chatMessage,
  members: {
    type: 'fdc3.contactList',
    contacts: [
      {
        type: 'fdc3.contact',
        id: {
          email: 'btreves@statestreet.com',
        },
      },
      {
        type: 'fdc3.contact',
        id: {
          email: 'yyleung@statestreet.com',
        },
      },
    ],
  },
  options: {
    groupRecipients: true,
  },
}

const SymphonyTest: React.FC = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Symphony FDC3 Interop
      </Typography>
      <br />
      <Button
        onClick={() =>
          raiseIntent('ViewMessages', {
            type: 'fdc3.searchCriteria',
            contexts: [
              {
                type: 'fdc3.instrument',
                id: {
                  ticker: 'EURGBP',
                },
              },
            ],
          })
        }
      >
        View $EURGBP Messages
      </Button>
      <Button
        onClick={() =>
          raiseIntent('ViewMessages', {
            type: 'fdc3.searchCriteria',
            contexts: [
              {
                type: 'fdc3.instrument',
                id: {
                  ticker: 'GBPUSD',
                },
              },
            ],
          })
        }
      >
        View $GBPUSD Messages
      </Button>
      <br />
      <br />
      <Button onClick={() => raiseIntent('StartChat', chatInit, { appId: 'Symphony' })}>Start New Chat</Button>
      <Button
        onClick={() => {
          raiseIntent('SendChatMessage', {
            type: 'fdc3.chat.message',
            chatRoom: {
              type: 'fdc3.chat.room',
              providerName: 'Symphony',
              id: {
                streamIds: ['erWFYcFW7UrcDbuSC4R5x3///ns2QLgVdA=='],
              },
              uri: 'http://symphony.com/ref/room/erWFYcFW7UrcDbuSC4R5x3///ns2QLgVdA==',
              name: 'seahorse',
            },
            message: {
              text: {
                'text/markdown': '@ippdevbot2 (DEV2) mytopfunds',
              },
            },
          })
        }}
      >
        Send Message to #seahorse
      </Button>
      <br />
      <br />
      <Button
        onClick={() => {
          notify({
            title: 'Order Matched',
            content: 'Order #155820 matched',
            actions: [
              {
                type: 'intent',
                title: 'Share via Symphony',
                intent: 'StartChat',
                context: chatInit,
                target: 'Symphony',
              },
              {
                type: 'intent',
                title: 'Share to #seahorse',
                intent: 'SendChatMessage',
                context: {
                  type: 'fdc3.chat.message',
                  chatRoom: {
                    type: 'fdc3.chat.room',
                    providerName: 'Symphony',
                    id: {
                      streamIds: ['erWFYcFW7UrcDbuSC4R5x3///ns2QLgVdA=='],
                    },
                    uri: 'http://symphony.com/ref/room/erWFYcFW7UrcDbuSC4R5x3///ns2QLgVdA==',
                    name: 'seahorse',
                  },
                  message: chatMessage,
                },
                target: 'Symphony',
              },
            ],
          })
        }}
      >
        Raise notification
      </Button>
      <br />
    </div>
  )
}

export default SymphonyTest
