import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'DimensionWidget' })({
  rightSpacing: {
    marginRight: 5,
    label: {
      marginLeft: 0,
    },
  },
  firstRow: {
    marginTop: 5,
  },
  row: {
    display: 'flex',
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    whiteSpace: 'nowrap',
    width: '100%',
  },
  radioGroup: {
    position: 'relative',
    '.MuiRadio-root': {
      padding: 3,
    },
  },
})
