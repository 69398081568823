import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { addIntentListener } from '@seahorse/api/fdc3'

export const FxConnectDemoWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    addIntentListener('seahorse.ShowOrders', () => {
      navigate('/fxc-demo/orders')
    })

    addIntentListener('seahorse.ShowOrderHistory', () => {
      navigate('/fxc-demo/history')
    })
  }, [navigate])

  return <>{children}</>
}
