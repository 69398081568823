import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'SessionRefreshWidget' })({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    margin: 5,
  },
})
