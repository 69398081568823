import React from 'react'
import { KPICurrency } from '@seahorse/ui'
import { BarChart, BarChartOptions } from '@seahorse/charts'
import { useStyles } from './Widget.styles'

const mockGraphData = {
  'Segment 1': 2,
  'Segment 2': 3,
  'Segment 3': 4,
  'Segment 4': 5,
}

const options: BarChartOptions = {
  axes: {
    fontSize: 10,
  },
  yAxis: {
    width: 70,
  },
  xAxis: {
    height: 15,
    hideAxisLine: true,
  },
  bars: {
    size: 6,
  },
  tooltip: {
    fontSize: 10,
    show: true,
  },
  margin: {
    top: 4,
    bottom: 4,
  },
}

const FxConnectHorizontalBar: React.FunctionComponent = () => {
  const { classes } = useStyles()
  return (
    <div className={classes.wrapper}>
      <KPICurrency value={388000000} label="GROSS USD" />
      <BarChart data={mockGraphData} colors={{}} variant="horizontal" options={options} />
    </div>
  )
}

export default FxConnectHorizontalBar
