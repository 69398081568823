import { makeStyles, StyledTheme } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'DownloadBlotter' })({
  rightSpacing: {
    marginRight: 5,
  },
  row: {
    display: 'flex',
    marginBottom: 5,
  },
  button: {
    width: '100%',
  },
})

export const useContainerStyles = makeStyles()((theme: StyledTheme) => ({
  root: {
    height: 400,
    border: `1px solid ${theme.globallink.global.accentPrimary}`,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: 10,
    display: 'flex',
  },
  button: {
    margin: '0 10px',
  },
}))
