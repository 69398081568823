import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logger } from '@seahorse/api'
import { addIntentListener, createPrivateChannel, Listener } from '@seahorse/api/fdc3'

const Wrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    let viewColorListener: Listener
    let viewGradientListener: Listener
    let getQuoteListener: Listener

    const colorHandler = async (context: any) => {
      logger.log('Received context', context)
      const color = context.hexValue?.slice(1)

      navigate('/color-viewer/' + color)

      return context
    }

    const gradientHandler = (context: any) => {
      logger.log('Received context', context)
      const colors = context.colors.map((color: any) => color.hexValue.slice(1))

      navigate('/gradient-viewer/' + colors.join(','))
    }

    /**
     * Intent handler to demo handler returning either a context, or a private channel

    Example:
    // get live quotes
    const intent = await fdc3.raiseIntent('seahorse.GetQuote', { type: 'seahorse.quote', live: true})
    const channel = await intent.getResult() // handler returns a private channel
    channel.addContextListener(console.log) // show quotes every second
    channel.disconnect() // stop streaming quotes

    // get single quote
    const intent = await fdc3.raiseIntent('seahorse.GetQuote', { type: 'seahorse.quote' })
    const context = await intent.getResult() // handler returns a context
    console.log(context) // show single quote

    */
    const getQuoteHandler = async (context: { type: string; live?: boolean }) => {
      const getQuote = () => ({
        type: 'seahorse.quote',
        quote: (Math.random() * 1_000).toFixed(2),
      })
      if (context.live) {
        // return private channel streaming quotes
        const channel = await createPrivateChannel()
        const intervalId = window.setInterval(() => channel.broadcast(getQuote()), 1_000)

        channel.onDisconnect(() => {
          window.clearInterval(intervalId)
        })

        return channel
      } else {
        // return single quote as a context object
        return getQuote()
      }
    }

    const init = async () => {
      try {
        viewColorListener = await addIntentListener('seahorse.ViewColor', colorHandler)

        viewGradientListener = await addIntentListener('seahorse.ViewGradient', gradientHandler)

        getQuoteListener = await addIntentListener('seahorse.GetQuote', getQuoteHandler)
      } catch (e) {
        logger.warn(e)
      }
    }

    init()

    return () => {
      viewColorListener?.unsubscribe()
      viewGradientListener?.unsubscribe()
      getQuoteListener?.unsubscribe()
    }
  }, [navigate])

  return <>{children}</>
}

export default Wrapper
