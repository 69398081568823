import React, { FC, useState } from 'react'
import { Button, SizeProvider, Tooltip, TextField } from '@seahorse/ui'
import { raiseIntent, raiseIntentForContext, open } from '@seahorse/api/fdc3'
import { useStyles } from './ColorSelectorWidget.styles'

const ColorSelectorWidget: FC = () => {
  const { classes } = useStyles()
  const [selectedColor, setSelectedColor] = useState<string | undefined>('#F00')
  const [selectedTarget, setSelectedTarget] = useState<string | undefined>('seahorse__simple-app')

  const isTargetSelected = Boolean(selectedTarget)

  return (
    <SizeProvider value="small">
      <div className={classes.container}>
        <div className={classes.settings}>
          <Tooltip content="Color">
            <TextField
              variant="underline"
              value={selectedColor}
              onChange={setSelectedColor}
              placeholder="Color (eg. #F00)"
              className={classes.colorTextField}
            ></TextField>
          </Tooltip>

          <Tooltip content="Target Widget">
            <TextField
              variant="underline"
              value={selectedTarget}
              onChange={setSelectedTarget}
              placeholder="Target"
              className={classes.targetTextfield}
            ></TextField>
          </Tooltip>
        </div>

        <div className={classes.buttons}>
          <Button
            className={classes.button}
            onClick={() => {
              raiseIntent(
                'seahorse.ViewColor',
                { type: 'seahorse.color', hexValue: selectedColor },
                selectedTarget ? { appId: selectedTarget } : undefined,
              )
            }}
            disabled={!isTargetSelected || !selectedColor}
          >
            Raise
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              raiseIntentForContext(
                { type: 'seahorse.color', hexValue: selectedColor },
                isTargetSelected ? { appId: selectedTarget as string } : undefined,
              )
            }}
            disabled={!selectedColor}
          >
            Raise For Ctx
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              open({ appId: selectedTarget as string }, { type: 'seahorse.color', hexValue: selectedColor })
            }}
            disabled={!selectedColor || !isTargetSelected}
          >
            Open
          </Button>
        </div>
      </div>
    </SizeProvider>
  )
}

export default ColorSelectorWidget
