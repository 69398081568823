import React, { FC, useState } from 'react'
import { Button, RadioGroup, RadioGroupItemProps, SizeProvider, TextField } from '@seahorse/ui'
import { useStyles } from './PreferencesWidget.styles'
import { getGroupPreference, setGroupPreference, getAppPreference, setAppPreference } from '@seahorse/api'
import { raiseIntent } from '@seahorse/api/fdc3'

const preferences: RadioGroupItemProps[] = [
  {
    label: 'App',
    value: 'app',
  },
  {
    label: 'Group',
    value: 'group',
  },
]

const PreferencesWidget: FC = () => {
  const { classes, cx } = useStyles()
  const [preferenceType, setPreferenceType] = useState('app')
  const [key, setKey] = useState<string | undefined>(undefined)
  const [value, setValue] = useState<string | undefined>(undefined)
  const isGroupPreference = preferenceType === 'group'

  return (
    <SizeProvider value="small">
      <div>
        <div className={cx(classes.firstRow, classes.row)}>
          <TextField
            className={classes.rightSpacing}
            variant="standard"
            value={key}
            onChange={setKey}
            placeholder={`${isGroupPreference ? 'Group' : 'App'} Key`}
          ></TextField>
          <TextField
            variant="standard"
            value={value}
            onChange={setValue}
            placeholder={`${isGroupPreference ? 'Group' : 'App'} Value`}
          ></TextField>
        </div>
        <div className={classes.row}>
          <RadioGroup
            row
            size="small"
            className={classes.radioGroup}
            items={preferences}
            value={preferenceType}
            onChange={(preference: string | null) => {
              if (preference) {
                setPreferenceType(preference)
              }
            }}
          />
          <Button
            size="small"
            className={cx(classes.rightSpacing, classes.button)}
            onClick={async () => {
              if (key) {
                const preference = isGroupPreference ? await getGroupPreference(key) : await getAppPreference(key)
                await raiseIntent('seahorse.GetPreference', { type: preferenceType, key: key, value: preference })
              }
            }}
          >
            Get
          </Button>
          <Button
            className={classes.button}
            onClick={async () => {
              if (key && value) {
                isGroupPreference ? await setGroupPreference(key, value) : await setAppPreference(key, value)
              }
            }}
          >
            Set
          </Button>
        </div>
      </div>
    </SizeProvider>
  )
}

export default PreferencesWidget
