import React, { useState, FC, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import {
  Button,
  CheckboxGroupItemProps,
  CheckboxGroup,
  TextField,
  RadioGroup,
  RadioGroupItemProps,
  Tooltip,
  SizeProvider,
} from '@seahorse/ui'
import { updateBadge, clearAllBadges, UpdateBadgeArgs } from '@seahorse/api'
import { useStyles } from './BadgeWidget.styles'

interface BadgeOptions {
  icon?: boolean
  inlineImage?: boolean
  clearOnAction?: boolean
}

const levels: RadioGroupItemProps[] = [
  {
    label: 'Urgent',
    value: 'urgent',
  },
  {
    label: 'Warn',
    value: 'warn',
  },
  {
    label: 'Info',
    value: 'info',
  },
  {
    label: 'Rdy',
    value: 'ready',
  },
]

const BadgeWidget: FC = () => {
  const { classes } = useStyles()
  const [selectedLevel, setSelectedLevel] = useState<UpdateBadgeArgs['level']>('ready')
  const [selectedOptions, setSelectedOptions] = useState<BadgeOptions>({})
  const [targetWidget, setTargetWidget] = useState<string>('example')
  const rawIcon = selectedOptions.icon
    ? renderToString(
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path
            fill="#A2C0D8"
            d="M20,2H4A2,2,0,0,0,2.01,4L2,22l4-4H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2ZM6,9H18v2H6Zm8,5H6V12h8Zm4-6H6V6H18Z"
          />
        </svg>,
      )
    : undefined
  const icon = rawIcon
    ? selectedOptions.inlineImage
      ? `<img src="data:image/svg+xml;base64,${window.btoa(rawIcon)}" />`
      : rawIcon
    : undefined

  const badgeOptions: CheckboxGroupItemProps[] = useMemo(
    () => [
      {
        label: 'Icon',
        value: 'icon',
      },
      {
        label: 'Inline img',
        value: 'inlineImage',
        disabled: !selectedOptions.icon,
      },
      {
        label: 'Clear on clk',
        value: 'clearOnAction',
      },
    ],
    [selectedOptions.icon],
  )

  const selectedOptionsList = (Object.keys(selectedOptions) as (keyof BadgeOptions)[]).filter(
    (key) => selectedOptions[key],
  )

  const handleBadgeOptionsChange = (change: (keyof BadgeOptions)[]) => {
    const updatedOptions = change.reduce<BadgeOptions>((acc, curr) => {
      acc[curr] = true
      return acc
    }, {})
    if (!updatedOptions.icon) {
      updatedOptions.inlineImage = false
    }
    setSelectedOptions(updatedOptions)
  }

  return (
    <SizeProvider value="small">
      <div className={classes.root}>
        <RadioGroup
          className={classes.radioGroup}
          row
          items={levels}
          value={selectedLevel}
          onChange={(change) => {
            if (change) {
              setSelectedLevel(change as UpdateBadgeArgs['level'])
            }
          }}
        />
        <CheckboxGroup
          className={classes.checkboxGroup}
          row
          value={selectedOptionsList}
          items={badgeOptions}
          onChange={handleBadgeOptionsChange}
        />

        <div className={classes.wrapper}>
          <Button
            className={classes.button}
            disabled={!targetWidget}
            onClick={() => {
              const randomCount = Math.round(Math.random() * 1500)

              updateBadge({
                level: selectedLevel,
                widgetId: targetWidget,
                count: randomCount,
                tooltip: selectedOptions.icon ? 'View Color' : randomCount.toString(),
                action: {
                  type: 'intent',
                  intent: 'seahorse.ViewColor',
                  context: { type: 'seahorse.color', hexValue: '#FFF' },
                },
                icon,
                clearOnAction: selectedOptions.clearOnAction,
              })
            }}
          >
            Update
          </Button>
          <Button onClick={() => clearAllBadges(targetWidget)} className={classes.button} disabled={!targetWidget}>
            Clear
          </Button>
          <Tooltip content="Target Widget">
            <TextField
              variant="underline"
              value={targetWidget}
              onChange={setTargetWidget}
              className={classes.targetTextField}
              placeholder="Target"
            ></TextField>
          </Tooltip>
        </div>
      </div>
    </SizeProvider>
  )
}

export default BadgeWidget
