import React, { useState, FC, useEffect } from 'react'
import { Button, TrashCanIcon, SearchItem, DropdownItem } from '@seahorse/ui'
import { useContainerStyles } from './DownloadBlotter.styles'
import {
  AgGrid,
  AgGridTypes,
  dateFormatter,
  DropdownCellEditor,
  DropdownCellRenderer,
  IconButtonCellRenderer,
  NumberCellEditor,
  numberFormatter,
  RadioCellRenderer,
  SearchCellEditor,
  SwitchCellRenderer,
  timeFormatter,
} from '@seahorse/ag-grid'

const DownloadBlotter: FC = () => {
  const rowCount = 100
  const { classes } = useContainerStyles()
  const [rowData, setRowData] = useState<any>([])
  const BANK_LIST = ['ABC Bank', 'Bank of Boston', 'Dynasty Bank', 'Gold', 'Interpay', 'Investment']
  const [api, setApi] = useState<AgGridTypes.GridApi>()
  const generateDataSet = () => {
    const data = new Array(rowCount).fill(0).map((_, index) => ({
      id: index,
      bank: BANK_LIST[index % BANK_LIST.length],
      date: new Date(Date.now() - 3600 * index * 1000),
      value: +(Math.random() * 1000000).toFixed(2),
      type: index % 3 ? 'BUY' : 'SELL',
      radio: false,
      switch: false,
      dropdown: undefined,
      search: undefined,
    }))
    setRowData(data)
  }

  const DROPDOWN_COLUMN_ITEMS: DropdownItem[] = [
    {
      label: 'Option 1',
      value: { label: 'Option 1', value: 'option1' },
    },
    {
      label: 'Option 2',
      value: { label: 'Option 2', value: 'option2' },
    },
    {
      label: 'Option 3',
      value: { label: 'Option 3', value: 'option3' },
      disabled: true,
    },
  ]

  const SEARCH_COLUMN_ITEMS: SearchItem[] = [
    {
      label: 'Option 1',
      value: { label: 'Option 1', value: 'option1' },
    },
    {
      label: 'Option 2',
      value: { label: 'Option 2', value: 'option2' },
    },
    {
      label: 'Option 3',
      value: { label: 'Option 3', value: 'option3' },
      disabled: true,
    },
  ]

  const columnDefs: AgGridTypes.ColDef[] = [
    { field: 'bank', checkboxSelection: true, headerCheckboxSelection: true, filter: 'agTextColumnFilter' },
    { field: 'date', valueFormatter: dateFormatter, type: 'rightAligned', filter: 'agDateColumnFilter' },
    {
      field: 'date',
      headerName: 'Time',
      valueFormatter: timeFormatter,
      type: 'rightAligned',
      filter: 'agDateColumnFilter',
    },
    {
      field: 'value',
      valueFormatter: numberFormatter(),
      type: 'rightAligned',
      editable: true,
      cellEditor: NumberCellEditor,
      cellEditorParams: { precision: 2, min: 0 },
    },
    { field: 'type' },
    { field: 'radio', cellRenderer: RadioCellRenderer, cellRendererParams: { enforceSingleSelection: true } },
    { field: 'switch', cellRenderer: SwitchCellRenderer },
    {
      field: 'dropdown',
      editable: true,
      cellEditor: DropdownCellEditor,
      cellEditorParams: {
        placeholder: 'Make Selection',
        items: DROPDOWN_COLUMN_ITEMS,
      },
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      cellRenderer: DropdownCellRenderer,
      valueFormatter: (params) => {
        return params.data.dropdown?.label || 'Make Selection'
      },
    },
    {
      field: 'search',
      editable: true,
      cellEditor: SearchCellEditor,
      cellEditorParams: {
        placeholder: 'Make Selection',
        items: SEARCH_COLUMN_ITEMS,
      },
      cellRenderer: DropdownCellRenderer,
      valueFormatter: (params) => {
        return params.data.search?.label || 'Make Selection'
      },
    },
    {
      width: 40,
      resizable: false,
      cellRenderer: IconButtonCellRenderer,
      cellRendererParams: {
        iconComponent: TrashCanIcon,
        onClick: () => {
          console.log('delete clicked')
        },
      },
      lockPosition: 'right',
    },
  ]

  useEffect(() => {
    generateDataSet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button onClick={() => api?.exportDataAsCsv()} className={classes.button}>
          Export CSV
        </Button>
        <Button onClick={() => api?.exportDataAsExcel()} className={classes.button}>
          Export Excel
        </Button>
      </div>
      <AgGrid
        onGridReady={(e: AgGridTypes.GridReadyEvent) => setApi(e.api)}
        columnDefs={columnDefs}
        enableEnterpriseFeatures={true}
        enableRangeSelection={true}
        rowData={rowData}
        // @ts-ignore
        getRowId={(data) => data.id}
        stopEditingWhenCellsLoseFocus
      />
    </div>
  )
}

export default DownloadBlotter
