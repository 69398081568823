import { makeStyles } from '@seahorse/ui'

export const useStyles = makeStyles({ name: 'ColorSelectorWidget' })({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  settings: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  targetTextfield: {
    margin: 5,
  },
  colorTextField: {
    width: 100,
    marginRight: 10,
    margin: 5,
  },
  buttons: {
    display: 'flex',
    margin: 'auto',
  },
  button: {
    margin: 5,
    whiteSpace: 'nowrap',
    padding: '5px 10px',
  },
})
