import React from 'react'
import { KPINumeric, makeStyles, useTheme } from '@seahorse/ui'
import { BarChartOptions, Legend, StackBarChart } from '@seahorse/charts'
import { useFluidWidgetFontSizeClassName } from '@seahorse/platform-ui'

const mockGraphData = [
  {
    title: 'Matched',
    FX: 12,
    FXO: 20,
    MMF: 17,
  },
  {
    title: 'Unmatched',
    FX: 2,
    FXO: 7,
    MMF: 11,
  },
  {
    title: 'Mismatched',
    FX: 3,
    FXO: 10,
    MMF: 15,
  },
  {
    title: 'Error',
    FX: 2,
    FXO: 4,
    MMF: 1,
  },
]

const useStyles = makeStyles()({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '90px auto',
    gridColumnGap: '2px',
    width: '100%',
  },
  chartWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
  },
})

const options: BarChartOptions = {
  yAxis: {
    width: 70,
  },
  xAxis: {
    height: 15,
    hideAxisLine: true,
  },
  bars: {
    size: 5,
  },
  tooltip: {
    show: true,
  },
  margin: {
    right: 20,
  },
  hideTicks: true,
  barCounts: {
    show: true,
  },
}

const legendItems = [
  {
    key: 'FX',
    label: 'FX',
  },
  {
    key: 'FXO',
    label: 'FXO',
  },
  {
    key: 'MMF',
    label: 'MMF',
  },
]

const TradeNexusStackChart: React.FunctionComponent = () => {
  const { classes } = useStyles()
  const theme = useTheme()
  const fluidFontSizeClassName = useFluidWidgetFontSizeClassName({ min: 10, max: 12 })

  const colors = {
    FX: theme.globallink.colors.DataOrange[500],
    FXO: theme.globallink.colors.DataOlive[600],
    MMF: theme.globallink.colors.DataTurquoise[500],
  }

  return (
    <div className={classes.wrapper}>
      <KPINumeric value={12} label="Exceptions" />
      <div className={classes.chartWrapper}>
        <Legend items={legendItems} colors={colors} size="small" />
        <StackBarChart
          className={fluidFontSizeClassName}
          data={mockGraphData}
          barFields={['FX', 'FXO', 'MMF']}
          variant="horizontal"
          colors={colors}
          options={options}
          labelField="title"
        />
      </div>
    </div>
  )
}

export default TradeNexusStackChart
